import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../../../App/redux/store';
import { appManagerActions } from '../../../../store/appManager/actions';
import { modalsActions } from '../../../../store/modals/actions';
import { adActions } from '../../adSlice';
import { adsNotifications } from '../../notifications';
import { PageType } from '../../useAds';
import deleteAd from '../deleteAd';
import fetchAdsThunk from './fetchAdsThunk';

const deleteAdsThunk = createAsyncThunk(
  'ad/deleteAdsThunk',
  async (params: { pageOrigin: PageType | null }, { dispatch, getState }) => {
    const { pageOrigin } = params;
    const state = getState() as RootState;
    const ad = state.ad;

    try {
      const idsToDelete: string[] = [];

      if (ad.activeAd.result) {
        // if ad detail is open
        idsToDelete.push(ad.activeAd.result.idIri);
        if (ad.selectedAds.map((m) => m.idIri).includes(ad.activeAd.result.idIri)) {
          // if activeAd is in selectedAds dispatch  for remove it
          dispatch(adActions.selectedAdsAdd(ad.activeAd.result));
        }
      } else if (!isEmpty(ad.selectedAds)) {
        // if ad card selection
        idsToDelete.push(...ad.selectedAds.map((m) => m.idIri));
      }
      // if no ids, no delete and no page reload
      if (isEmpty(idsToDelete)) return true;

      // delet ads requests
      await Promise.all(idsToDelete.map((m) => deleteAd(m)));

      dispatch(modalsActions.deleteAds.close());

      // if activeAd, close right panel
      if (ad.activeAd.result) dispatch(modalsActions.adDetails.close());

      dispatch(
        fetchAdsThunk({
          ...ad.pagination,
          pageType: pageOrigin,
        })
      );
      dispatch(
        appManagerActions.actionNotificationDisplay.visible(
          adsNotifications.deleteAds.success()
        )
      );
    } catch (error) {
      dispatch(modalsActions.deleteAds.close());
      if (ad.activeAd.result) {
        dispatch(modalsActions.adDetails.close());
      }
      dispatch(
        appManagerActions.actionNotificationDisplay.visible(
          adsNotifications.deleteAds.failure()
        )
      );
      return Promise.reject(error);
    }
  }
);

export default deleteAdsThunk;
