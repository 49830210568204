import { useAppSelector } from '../../../../App/redux/store';
import Button from '../../../../components/Assets/Button';
import { getAdState } from '../../adSlice';
import styles from './adLoadErrorContent.module.scss';
function AdLoadErrorContent() {
  const { activeAd } = useAppSelector(getAdState);

  const error =
    activeAd.error && activeAd.error.message
      ? activeAd.error.message.split('/')
      : null;

  const status = error?.[0] ?? 'unknown';

  const handleSupport = () => {
    window.open('mailto:support@urbanease.io', '_blank');
  };
  return (
    <div className={styles.adLoadErrorContent}>
      <h1>Erreur de chargement</h1>
      {status === '404' && (
        <p>Le contenu de cette annonce n&apos;a pas été trouvé.</p>
      )}
      {status !== '404' && <p>Le chargement de cette annonce a échoué.</p>}
      <p>Si le problème se reproduit, vous pouvez contacter le support.</p>

      <Button
        primary
        btnType="button"
        btnContent="Je contacte le support"
        handleClick={handleSupport}
      />
    </div>
  );
}

export default AdLoadErrorContent;
