import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchAd from '../fetchAd';

const fetchAdThunk = createAsyncThunk(
  'ad/fetchAdThunk',
  async (params: { idIri: string }) => {
    try {
      const result = await fetchAd({ idIri: params.idIri });

      return result;
    } catch (error: any) {
      let message = error?.status ?? error?.data?.status ?? '000';
      message += '/' + (error?.data?.detail ?? 'unknown');
      return Promise.reject(message);
    }
  }
);

export default fetchAdThunk;
