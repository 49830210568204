import ActionModal from '../../../../components/Modals/ActionModal';
import { adActions } from '../../adSlice';

import deleteAdsThunk from '../../services/thunks/deleteAdsThunk';
import useAds from '../../useAds';
import './adsDeleteModal.scss';

const AdsDeleteModal = () => {
  const { selectedAds, pageOrigin } = useAds({ noLoadAds: true });

  const qty = selectedAds.length;
  return (
    <ActionModal
      closeAction={adActions.selectedAdsReset()}
      validAction={deleteAdsThunk({
        pageOrigin,
      })}
      validText="SUPPRIMER"
      modalType="suppression"
    >
      <p>
        Confirmez-vous la suppression
        {qty > 1
          ? ` des ${qty} annonces sélectionnées `
          : ` de l'annonce sélectionnée `}
        sur votre pige classique et vos recherches actives ?
      </p>
      <p>La suppression sera définitive.</p>
    </ActionModal>
  );
};

export default AdsDeleteModal;
