import { useDispatch } from 'react-redux';

import { foldersActions } from '../../store/folders/actions';

import LogoButton from '../Assets/LogoButton';
import Badge from '../badge';

import useAuth from '../../App/hooks/useAuth';
import useFolders from '../../App/hooks/useFolders';
import { modalsActions } from '../../store/modals/actions';
import {
  BadgeBackgroundColorEnum,
  BadgeContentEnum,
  BadgeTextColorEnum,
} from '../badge/enums';
import FolderLineIcon from './ForlderLineIcon';

export interface IFolderLineProps {
  folder: any;
}
function FolderLine({ folder }: IFolderLineProps) {
  const { userIri } = useAuth();

  const { activeFolder } = useFolders();
  const dispatch = useDispatch();

  if (!folder) return null;

  const handleFolderUpdate = () => {
    dispatch(modalsActions.modifyFolders.open(folder));
  };
  const handleSetActive = () => {
    if (activeFolder && activeFolder === folder.idIri) {
      dispatch(foldersActions.setActiveFolder.reset());
    } else {
      dispatch(foldersActions.setActiveFolder.set(folder.idIri));
    }
  };

  const isOwner = folder?.owner.idIri === userIri;

  return (
    <tr
      className={
        folder?.isDefault ? 'my-folders-content default' : 'my-folders-content'
      }
    >
      <td>
        <FolderLineIcon folder={folder} isActive={activeFolder === folder?.idIri} />
      </td>
      <td className="my-folders-desktop">
        {folder.hasUpdates && (
          <Badge
            badgeContent={BadgeContentEnum.MISE_A_JOUR}
            bgColor={BadgeBackgroundColorEnum.GREEN}
            textColor={
              folder.isDefault ? BadgeTextColorEnum.VIOLET : BadgeTextColorEnum.WHITE
            }
            isMini
          />
        )}
      </td>
      {folder?.isDefault ? (
        <td>Par défaut</td>
      ) : (
        <td className="my-folders-desktop">{`${
          folder?.totalCollaborators
        } collaborateur${folder.totalCollaborators > 1 ? 's' : ''}`}</td>
      )}
      <td className="my-folders-desktop">
        <Badge
          badgeDynamicContent={`${folder?.totalAdStudies} annonces`}
          bgColor={
            folder?.isDefault
              ? BadgeBackgroundColorEnum.VIOLET
              : BadgeBackgroundColorEnum.GREY
          }
          textColor={
            folder?.isDefault
              ? BadgeTextColorEnum.WHITE
              : BadgeTextColorEnum.DARKER_GREY
          }
          isMini
        />
      </td>
      <td className="my-folders-mobile">
        <LogoButton
          btnType="button"
          handleClick={handleSetActive}
          btnLogo="threePoints"
          tooltip={`Actions`}
        />
      </td>
      <td className="my-folders-desktop">
        {isOwner && !folder?.isDefault && (
          <div className="my-folders-actions">
            <LogoButton
              btnType="button"
              handleClick={handleFolderUpdate}
              btnLogo="pen-violet"
              tooltip="Modifier"
            />
            <LogoButton
              btnType="button"
              handleClick={() =>
                dispatch(
                  foldersActions.deleteFolder.modal({
                    display: true,
                    idIri: folder?.idIri,
                    name,
                  })
                )
              }
              btnLogo="delete-red"
              tooltip={`Supprimer`}
            />
          </div>
        )}
      </td>
    </tr>
  );
}

export default FolderLine;
