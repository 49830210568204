import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { similariesTableHeader } from '../../../../components/AdDetails/constant';
import GeoContent from '../../../../components/AdDetails/geo/GeoContent';
import AdBasicDetails from '../../../../components/Assets/AdBasicDetails';
import Button from '../../../../components/Assets/Button';
import LogoButton from '../../../../components/Assets/LogoButton';
import PaginationCustom from '../../../../components/Assets/PaginationCustom';
import Badge from '../../../../components/badge';
import {
  BadgeBackgroundColorEnum,
  BadgeTextColorEnum,
} from '../../../../components/badge/enums';
import AdTableCustom from '../../../../components/ComparativeStudies/Details/AdTableCustom';
import IndividualCircularLoader from '../../../../components/Loader/IndividualCircularLoader';
import { APIStatus } from '../../../../services/axiosEnums';
import { adStudiesActions } from '../../../../store/adStudies/actions';
import { modalsActions } from '../../../../store/modals/actions';
import { euroFormat } from '../../../../utils/jsFunctions';
import { adActions } from '../../adSlice';
import useAds from '../../useAds';
import AdLoadErrorContent from '../adLoadErrorContent/AdLoadErrorContent';
import './AdDetails.scss';

interface IAdDetailsProps {
  adId: string;
}
function AdDetails({ adId }: IAdDetailsProps) {
  const { activeAd, similaryAd } = useAds({ noLoadAds: true, adId });
  const [minIndexTab, setMinIndexTab] = useState(0);
  const display = 20;
  const ad = adId ? similaryAd : activeAd.result;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adActions.plotReset());
  }, [activeAd]);

  const handleFolder = () => {
    dispatch(adStudiesActions.createAdStudies.modal());
  };

  const handleDelete = () => {
    if (ad) {
      dispatch(modalsActions.deleteAds.open());
    }
  };

  if (activeAd.apiStatus === APIStatus.PENDING) {
    return <IndividualCircularLoader size={200} />;
  }

  return (
    <>
      {!adId && activeAd.apiStatus === APIStatus.REJECTED && (
        <div className="ad-details">
          <AdLoadErrorContent />
        </div>
      )}
      {ad && (
        <div className="ad-details">
          <header className="ad-details-header">
            <div className="ad-details-header-info">
              <h2 className="ad-details-header-title">
                {ad.totalRoom > 0
                  ? `${ad.propertyTypology.name} F${ad.totalRoom}`
                  : `${ad.propertyTypology.name}`}
                <Badge
                  badgeDynamicContent={
                    ad.price ? `${euroFormat(ad.price, true)} €` : 'Indéfini'
                  }
                  bgColor={BadgeBackgroundColorEnum.VIOLET}
                  textColor={BadgeTextColorEnum.WHITE}
                  isMini
                />
              </h2>
              <span className="ad-details-header-date">{`Publiée le ${new Date(
                ad.publishedAt
              ).toLocaleDateString()}`}</span>
            </div>
            <div className="ad-details-header-btns">
              <LogoButton
                btnType="button"
                handleClick={handleFolder}
                btnLogo="folder-wait"
                tooltip="A étudier"
              />
              <LogoButton
                btnType="button"
                handleClick={handleDelete}
                btnLogo="delete-red"
                tooltip="Supprimer"
              />
            </div>
          </header>

          <AdBasicDetails data={ad} />

          <section className="ad-details-ad-price">
            <Button
              btnType="button"
              btnContent="VOIR L'ANNONCE EN DETAILS"
              primary
              handleClick={() => window.open(ad.url, '_blank,noopener,noreferrer')}
            />
          </section>

          <section className="ad-details-search">
            <GeoContent ad={ad} />

            <div className="ad-details-similar">
              <h3 className="ad-details-section-title">{`Bien(s) similaire(s) à la vente récemment`}</h3>
              {isEmpty(ad.children) ? (
                <p className="no-content">Aucune annonce similaire trouvée</p>
              ) : isEmpty(ad) ? (
                <IndividualCircularLoader size={200} />
              ) : (
                <>
                  <AdTableCustom
                    columnTitles={similariesTableHeader}
                    adsArray={ad.children}
                    needCheck={false}
                    display={display}
                    minIndexTab={minIndexTab}
                  />
                  <PaginationCustom
                    elementsQty={ad.children.length}
                    itemsPerPage={display}
                    handleMinIndexTab={setMinIndexTab}
                  />
                </>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
}

AdDetails.propTypes = {
  adId: PropTypes.string,
};

export default AdDetails;
